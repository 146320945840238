import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import baseTheme from '../../styles/theme';

const BaseComponent =  forwardRef(({
  as: BaseComponent = 'div',
  children,
  color = 'primary',
  rounding = 'md',
  elevation = 'sm',
  margin = 'sm',
  padding = 'md',
  className,
  textSize = 'md',
  ...props
}, ref ) => {
  
  const isHexColor = /^#([0-9A-F]{3}){1,2}$/i.test(color);
  const textSizeClasses = {
    sm: 'text-sm py-1 px-2',
    md: 'text-base py-2 px-4',
    lg: 'text-lg py-3 px-6',
    // Add other sizes as needed
  };
  const baseStyles = {
    backgroundColor: isHexColor ? color : baseTheme.colors[color],
    borderRadius: baseTheme.borderRadius[rounding],
    boxShadow: baseTheme.shadows[elevation],
    padding: baseTheme.padding[padding],
    margin: baseTheme.spacing(baseTheme.margin[margin]),
    ...props.sx,
  };

  return (
    <BaseComponent
    style={baseStyles}
      className={`${textSizeClasses[textSize]} ${className || ''}`}
      {...props}
    >
      {children}
    </BaseComponent>
  );
});

BaseComponent.displayName = 'BaseComponent';

BaseComponent.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  sx: PropTypes.object, // Inline styling incremental to existing style
  color: PropTypes.string,
  rounding: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'full']),
  elevation: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'xl']),
  padding: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
  margin: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
  textSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
};

export default BaseComponent;