import React from "react";
import BaseComponent from "./base-component";

const Box = ({ children, ...props }) => {
  return (
    <BaseComponent 
      as="div" 
      elevation="none" 
      color="none" 
      margin='sm'
      padding='sm'
      {...props}>
      {children}
    </BaseComponent>
  );
};

export default Box;
