import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import GetToken from "./common/pages/GetToken.jsx";
import Home from "./common/pages/home.jsx";
import Logout from "./common/pages/Logout.jsx";
import NotFound from "./common/pages/NotFound.jsx";
import Unauthorized from "./common/pages/Unauthorized.tsx";
import { ApplicationProvider, useApplicationContext } from "./context/AppContext.jsx";
import { get } from "./services/api.tsx";

const AppContent = () => {
  const { isAuthenticated, setIsAuthenticated } = useApplicationContext();

  useEffect(() => {
    const checkAuthentication = async () => {
      const response = await get("auth/user").catch((error) => {
        console.error("Error fetching user data:", error);
        return false;
      });
      setIsAuthenticated(response ? true : false);
    };

    checkAuthentication();
  }, [setIsAuthenticated]);

  if (isAuthenticated === null) {
    // Show a loading indicator while authentication status is being determined
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? <Home /> : <Navigate to="/unauthorized"/>
          }
        />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/getToken" element={<GetToken />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <ApplicationProvider>
      <AppContent />
    </ApplicationProvider>
  );
};

export default App;