import React from 'react';

const SkeletonTab = ({ tabs = 0, cols = 4, rows = 2 }) => {
  return (
    <div className="animate-pulse">
      {/* Tabs skeleton */}
      {tabs > 0 && (
        <div className="flex mb-4">
          {[...Array(tabs)].map((_, index) => (
            <div key={index} className="mr-2 bg-gray-200 h-8 w-24 rounded"></div>
          ))}
        </div>
      )}
      
      {/* Grid skeleton */}
      <div className="bg-white shadow rounded-lg p-4">
        {/* Header row */}
        <div className={`flex mb-4`}>
          {[...Array(cols)].map((_, index) => (
            <div key={index} className="mr-2 bg-gray-200 h-6 w-full rounded"></div>
          ))}
        </div>

        {/* /* Data rows */}
          <div className={`grid mb-2`}>
        {[...Array(rows)].map((_, rowIndex) => (
              <div key={rowIndex} className="mb-2 h-4 bg-gray-200 rounded-full"></div>
            ))}
          </div>
      </div>
    </div>
  );
};

export default SkeletonTab;