import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';
import TabsContainer from '../../../../common/components/containers/TabsContainer';
import { useEktContext } from '../../context/EktContext';

const EktSideViewOne = ({ viewType }) => {
  const { 
      stockViewData, 
      stockOWHViewData,
      supplierOrdersData,
      loading,
      error,
       } = useEktContext();

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    if (loading){
    }
  };


  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const thousandSeparator = (params) => {
    const value = Number(params.value);
    return !isNaN(value) ? value.toLocaleString() : params.value;
  };

  const getColumnDefs = () => {
    const commonColumns = [{}];
    const rightAlignedStyle = { textAlign: 'right' }

    switch (viewType) {
      case 'FBA':
        return [
          { headerName: 'Region', field: '_id', sortable: true,  minWidth: 80, flex: 1 },
          { headerName: 'Total Stock Qt', field: 'total_stock_quantity', minWidth: 100, flex: 1 , valueFormatter: thousandSeparator,  cellStyle: rightAlignedStyle},
          { headerName: 'FBA/FBM Available Stock', field: 'available_stock', minWidth: 120, flex: 1 ,valueFormatter: thousandSeparator,  cellStyle: rightAlignedStyle},
          { headerName: 'Stock in Transit', field: 'stock_in_transit', minWidth: 100, flex: 1,valueFormatter: thousandSeparator,  cellStyle: rightAlignedStyle },
        ];
      case 'OWH':
        return [
          { headerName: 'Location', field: '_id',  minWidth: 150, flex: 1 },
          { headerName: 'Qty On Hand', field: 'quantity_on_hand', minWidth: 100, flex: 1 , valueFormatter: thousandSeparator,  cellStyle: rightAlignedStyle},
          { headerName: 'Qt Available', field: 'quantity_available', minWidth: 100, flex: 1 , valueFormatter: thousandSeparator,  cellStyle: rightAlignedStyle},
          { headerName: 'Avg Cost', field: 'average_cost', minWidth: 80, flex: 1,valueFormatter: thousandSeparator,  cellStyle: rightAlignedStyle },
          { headerName: 'On Hand Value', field: 'onhand_value', minWidth: 120, flex: 1 , valueFormatter: thousandSeparator,  cellStyle: rightAlignedStyle},
        ]
      case 'OSP': 
        return [
          { headerName: 'PO Number', field: 'PO_NUMBER', flex: 1 },
          { headerName: 'Order Date', field: 'ORDER_DATE',  cellStyle: rightAlignedStyle, flex: 1 },
          { headerName: 'Expected Date', field: 'EXPECTED_RECEIPT_DATE', cellStyle: rightAlignedStyle, flex: 1 },
          { headerName: 'PO Quantity', field: 'TOTAL_PO_QUANTITY', valueFormatter: thousandSeparator, cellStyle: rightAlignedStyle, flex: 1 },
          { headerName: 'Received Quantity', field: 'RECEIVED_QUANTITY', valueFormatter: thousandSeparator, cellStyle: rightAlignedStyle, flex: 1 },
          { 
            headerName: 'PO Remaining QTY', 
            valueGetter: (params) => {
              return params.data.TOTAL_PO_QUANTITY - params.data.RECEIVED_QUANTITY;
            },
            valueFormatter: thousandSeparator, cellStyle: rightAlignedStyle, flex: 1 ,
          },
          { headerName: 'Stored at Supplier', field: 'STORED_AT_SUPPLIER', valueFormatter: thousandSeparator,cellStyle: rightAlignedStyle, flex: 1 },
          { headerName: 'Order Status', field: 'ORDER_STATUS', cellStyle: rightAlignedStyle, flex: 1 },
          { headerName: 'PO Type', field: 'PO_TYPE', cellStyle: rightAlignedStyle, flex: 1 },
        ]
      default:
        return commonColumns;
    }
  };

  return (
    <div>
      <div className="ag-theme-balham " 
        style={{ 
            height: 300, 
            width: '100%',
            marginTop:'0.2rem'
          }}
      >
        <AgGridReact
          columnDefs={getColumnDefs()}
          rowData={ viewType === 'FBA' ? stockViewData 
                  : viewType === 'OWH' ? stockOWHViewData 
                  : supplierOrdersData
                }
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSizeSelector={false}
          paginationPageSize={1000}
          domLayout='normal'
          defaultColDef={{
            sortable: true,
            resizable: true,
            autoHeight: true,
            // wrapText: true,
            autoHeaderHeight: true,
          }}
          autoHeaderHeight={true}
          // sortingOrder={['asc', 'desc']}
          // defaultSortModel={[{ colId: '_id', sort: 'desc' }]}
        />
      </div>
    </div>
  );
};

export const TabsSideViewOne = () =>{
  const tabsData = [
    {
      title: 'FBA',
      content: <EktSideViewOne viewType="FBA" />,
    },
    {
      title: 'Other WH',
      content: <EktSideViewOne viewType="OWH" />,
    },
    {
      title: 'Orders Supplier',
      content: <EktSideViewOne viewType="OSP" />,
    },
  ];
  return(
    <TabsContainer
      tabs={tabsData}
      defaultActiveTab={0}
      rounding='sm'
      elevation ='none'
      padding='none'
      margin='none'
      />
  );
};