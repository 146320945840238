import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { EktProvider } from "../../apps/ekt/context/EktContext";
import EktUI from "../../apps/ekt/ekt-ui";
import Container from "../../common/components/containers/container";
import { ThemeProvider } from "../../context/ThemeContext";
import { get } from "../../services/api";
import { GlobalStyles } from "../../styles/global-styles";
import baseTheme from "../../styles/theme";

const Home = () => {
  const fetchPrivateData = async () => {
    const accessToken = localStorage.getItem("access_token");
    console.log("Access token:", accessToken);
    if (!accessToken) {
      alert("Access token is missing");
      return;
    }
    try {
      const response = await get("/private", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Private data:", response.data);
      alert("Private data: " + JSON.stringify(response.data));
      // Handle the private data as needed
    } catch (error) {
      console.error("Error fetching private data:", error);
    }
  };

  return (
    <ThemeProvider>
      <StyledThemeProvider theme={baseTheme}>
        <GlobalStyles />
          <EktProvider>
            <Container
              sx={{ backgroundColor: baseTheme.colors.background }}
              elevation="none"
              margin="none"
              padding="sm"
            >
              <EktUI />
            </Container>
          </EktProvider>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

export default Home;
