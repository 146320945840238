import { get, post } from '../../../services/api';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const withRetry = (fn, maxRetries = 2, delayMs = 1000) => {
  return async (...args) => {
    let lastError;
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        return await fn(...args);
      } catch (error) {
        console.warn(`Attempt ${attempt + 1} failed:`, error.message);
        lastError = error;
        if (attempt < maxRetries - 1) {
          await delay(delayMs);
        }
      }
    }
    throw lastError;
  };
};

export const fetchArticles = withRetry(async () => {
  try {
    const accessToken = localStorage.getItem('access_token');
    const response = await get(`/articles`);
    if (response.data.status_code === 200) {
      return response.data.data.articles;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch articles data');
  }
});

export const refreshArticles = async () => {
  try {
    const response = await get(`/articles/refresh`);
    if (response.data.status_code === 200) {
      return response.data.message;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to refresh articles data');
  }
};

export const fetchFilteredArticles = async (filters, collectionName) => {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append('collection_name', collectionName ? collectionName.toLowerCase() : 'reorder');
    
    if (filters.catalog) queryParams.append('catalog', filters.catalog);
    if (filters.categoryManager) queryParams.append('category_manager', filters.categoryManager);
    if (filters.category) queryParams.append('category', filters.category);
    if (filters.asin) queryParams.append('asin', filters.asin);
    if (filters.shortlabel1) queryParams.append('shortlabel1', filters.shortlabel1);
    if (filters.sku) queryParams.append('sku', filters.sku);
    if (filters.allArticles) queryParams.append('all_articles', 'true');
    if (filters.noArticleBundleList) queryParams.append('no_article_bundle_list', 'true');
    if (filters.rangeStockCritical) queryParams.append('range_stock_critical', 'true');
    if (filters.purchaseOrderTimeframeExceeded) queryParams.append('purchase_order_timeframe_exceeded', 'true');
    if (filters.overstock) queryParams.append('overstock', 'true');
    if (filters.newStarting) queryParams.append('new_starting', 'true');

    const response = await get(`articles/filtered?${queryParams.toString()}`);
    if (response.data.status_code === 200) {
      return response.data.data.articles;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error('Error fetching filtered articles:', error);
    throw error;
  }
};

export const fetchFBAStockData = async (asins = [], skus = []) => {
  try {
    const requestBody = {
      asin: Array.isArray(asins) ? asins : [],
      sku: Array.isArray(skus) ? skus : []
    };

    const response = await post(`current_stock/fba`, requestBody, { withCredentials: true });

    if (response.data.status_code === 200) {
      return response.data.data.stock_data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch current stock data');
  }
};

export const fetchOWHStockData = async (asins = [], skus = []) => {
  try {
    const requestBody = {
      asin: Array.isArray(asins) ? asins : [],

    };

    const response = await post(`current_stock/owh`, requestBody, { withCredentials: true });
    if (response.data.status_code === 200) {
      return response.data.data.stock_data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch current stock data');
  }
};

export const fetchSupplierOrders = async (asins = [], skus = []) => {
  try {
      const requestBody = {
        asin: Array.isArray(asins) ? asins : [],
        sku: Array.isArray(skus) ? skus : []
      };
      
    const response = await post(`supplier/orders`, requestBody, { withCredentials: true });
    console.log('fetchSupplierOrders response:', response);
    if (response.data.status_code === 200) {
      return response.data.data.orders_data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch supplier orders');
  }
}

export const fetchPMTData = async (asin = [], region = []) => {

  try {
    const requestBody = { 
      asin: asin,
      region: region
    };
    const response = await post(`/pmt`, requestBody, { withCredentials: true });
    if (response.data.status_code === 200) {
      return response.data.data.pmt_data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch PMT data');
  }
}