import React from 'react';

interface FilterButtonProps {
  active: boolean;
  children: React.ReactNode;
  onClick: () => void;
  size?: 'sm' | 'md' | 'lg';
}

export function FilterButton({ active, children, onClick, size = 'sm' }: FilterButtonProps) {
  const sizeClasses = {
    sm: 'px-2 py-1.5 text-xs',
    md: 'px-3 py-1.5 text-sm',
    lg: 'px-4 py-2 text-base'
  };

  return (
    <button
      onClick={onClick}
      className={`
        filter-button
        ${active ? 'filter-button-active' : 'filter-button-inactive'}
        ${sizeClasses[size]}
        w-full 
        hover:-translate-y-0.5 
        hover:shadow-lg
        rounded-md
        transition-all
        duration-200
      `}
    >
      {children}
    </button>
  );
}