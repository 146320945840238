import React from 'react';
import BaseSidebar from '../../../../common/components/bars/base-sidebar';
import Box from '../../../../common/components/box';
import BaseButton from '../../../../common/components/buttons/base-button';
import { Checkbox } from '../../../../common/components/Checkbox';
import { FilterButton } from '../../../../common/components/FilterButton';
import { Pin } from '../../../../common/components/icons/pin';
import BaseInput from '../../../../common/components/input/BaseInput';
import { useEktContext } from '../../context/EktContext';

const EktFilterSidebar = () => {
  const { 
    isSidebarOpen, 
    toggleSidebar, 
    filters, 
    handleFilterChange, 
    applyFilters, 
    categories,
    categoryManagers,
    models,
    handleResetDataFilters,
    pinSidebar,
    setPinSidebar
   } = useEktContext();


  return (
    <BaseSidebar
      isOpen={isSidebarOpen}
      onClose={toggleSidebar}
      position="left"
      behavior={pinSidebar ? 'pinned' : 'slide'}
      padding='none'
      size="md"
      className="top-20 z-20"
    >
      <Box padding="none" elevation="none"
       className="h-full "
       >

      <Pin
      width="15px"
      height="15px"
      onClick={() => setPinSidebar(!pinSidebar)}
      className="absolute top-2 right-2 cursor-pointer"
      />
        {/* Category Manager Selector */}
        <BaseInput
            as="select"
            label="Category Manager"
            labelPosition="column"
            id="categoryManager"
            value={filters?.categoryManager || ''}
            onChange={(e) => handleFilterChange('categoryManager', e.target.value)}
          >
            <option value="">all managers</option>
            {categoryManagers.map(cm => (
              <option key={cm} value={cm}>{cm}</option>
            ))}
        </BaseInput>
        
        <BaseInput
            label="Shortlabel"
            id="shortlabel"
            type="text"
            
            className="w-full h-6"
            value={filters?.shortlabel1 || ''}
            onChange={(e) => handleFilterChange('shortlabel1', e.target.value)}
          />

        <Box 
          padding="none" 
          margin="none" 
          className="grid grid-cols-2 gap-0">

          <BaseInput
            as="select"
            label="Prod. Category"
            labelPosition="column"
            id="category"
            value={filters?.category || ''}
            onChange={(e) => handleFilterChange('category', e.target.value)}
          > 
           <option value="">all categories</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </BaseInput>

          <BaseInput
            as="select"
            label="Model"
            labelPosition="column"
            id="model"
            value={filters?.model || ''}
            onChange={(e) => handleFilterChange('model', e.target.value)}
          >
            <option value="">all models</option>
            {models.map(model => (
              <option key={model} value={model}>{model}</option>
            ))}
          </BaseInput>

          <BaseInput
              label="SKU(s)"
              id="sku"
              type="text"
              labelPosition="column"
              className="w-full h-6"
              value={filters?.sku || ''}
              onChange={(e) => handleFilterChange('sku', e.target.value)}
            />
          {/* Article Filters */}
          <BaseInput
              label="ASIN(s)"
              id="asin"
              type="text"
              labelPosition="column"
              className="w-full h-6"
              value={filters?.asin || ''}
              onChange={(e) => handleFilterChange('asin', e.target.value)}
            />
            <BaseInput
              label="Data Block"
              id="buildingBlockName"
              type="text"
              labelPosition="column"
              className="w-full h-6"
              value={filters?.buildingBlockName || ''}
              onChange={(e) => handleFilterChange('buildingBlockName', e.target.value)}
            />
            <BaseInput
              label="Data Block ID"
              id="buildingBlock"
              type="text"
              labelPosition="column"
              className="w-full h-6"
              value={filters?.buildingBlock || ''}
              onChange={(e) => handleFilterChange('buildingBlock', e.target.value)}
            />
        </Box>

          <Box 
            padding="sm" 
            margin="sm" 
            rounding="none"
            className="border-t p-4 m-4"
          >
            <div className="grid grid-cols-2 gap-2">
              <Checkbox
                id="doNotReorder"
                label="not reorder"
                checked={filters?.isDoNotOrder || false}
                onChange={(e) => handleFilterChange('isDoNotOrder', e.target.checked)}
              />
              <Checkbox
                id="oos"
                label="OOS"
                checked={filters?.oosFlag || false}
                onChange={(e) => handleFilterChange('oosFlag', e.target.checked)}
              />
{/*               
              <Checkbox
                id="placeholder2"
                label="Placeholder 2"
                checked={false}
                onChange={() => {}}
              />
              <Checkbox
                id="placeholder3"
                label="Placeholder 3"
                checked={false}
                onChange={() => {}}
              /> */}
            </div>
          </Box>
        {/* Controls Section */}
        <Box 
          margin="sm" 
          padding="sm"
          rounding="none"
          className="border-t border-b pt-4 pb-4">
          <div className="grid grid-cols-2 gap-2 mt-2 mb-2">
            <FilterButton 
              active={filters?.allArticles}
              onClick={() => handleFilterChange('allArticles', !filters.allArticles)}>
              all Articles
            </FilterButton>
            <FilterButton 
              active={filters?.noArticleBundleList}
              onClick={() => handleFilterChange('noArticleBundleList', !filters.noArticleBundleList)}>
              no article bundle list
            </FilterButton>
            <FilterButton 
              active={filters?.rangeStockCritical}
              onClick={() => handleFilterChange('rangeStockCritical', !filters.rangeStockCritical)}>
              range stock critical
            </FilterButton>
            <FilterButton 
              active={filters?.purchaseOrderTimeframeExceeded}
              onClick={() => handleFilterChange('purchaseOrderTimeframeExceeded', !filters.purchaseOrderTimeframeExceeded)}>
              po timeframe exceeded
            </FilterButton>
            <FilterButton 
              active={filters?.overstock}
              onClick={() => handleFilterChange('overstock', !filters.overstock)}>
              overstock
            </FilterButton>
            <FilterButton
              active={filters?.newStarting}
              onClick={() => handleFilterChange('newStarting', !filters.newStarting)}>
              new starting
            </FilterButton>
          </div>
        </Box>

        <Box margin="md" className="mt-4 flex space-x-2">
          <BaseButton onClick={applyFilters} color="primary" className="flex-1">
            Apply Filters
          </BaseButton>
          <BaseButton onClick={() => handleResetDataFilters()} color="primary" className="flex-1">
            Clear Filters
          </BaseButton>
        </Box>
      </Box>
    </BaseSidebar>
  );
};

export default EktFilterSidebar;