import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  fetchArticles,
  fetchFBAStockData,
  fetchOWHStockData,
  fetchSupplierOrders,
  refreshArticles
} from "../handlers/viewHandler";

const filterData = (data, filters) => {
  if (!data || data.length === 0) return [];
  
  // Split SKU and ASIN filters into arrays and clean them
  const skuArray = filters.sku
    ? filters.sku.split(/[,;]/).map(s => s.trim()).filter(Boolean)
    : [];
  const asinArray = filters.asin
    ? filters.asin.split(/[,;]/).map(s => s.trim()).filter(Boolean)
    : [];

  // Create regex patterns
  const searchPatterns = {
    catalog: filters.catalog ? new RegExp(filters.catalog, 'i') : null,
    shortlabel1: filters.shortlabel1 ? new RegExp(filters.shortlabel1, 'i') : null,
    model: filters.model ? new RegExp(filters.model, 'i') : null,
    buildingBlock: filters.buildingBlock ? new RegExp(filters.buildingBlock, 'i') : null,
    buildingBlockName: filters.buildingBlockName ? new RegExp(filters.buildingBlockName, 'i') : null,

  };

  // Pre-lowercase case-sensitive filters
  const lowercaseFilters = {
    categoryManager: filters.categoryManager?.toLowerCase(),
    category: filters.category?.toLowerCase()
  };

  return data.filter(row => {
    // Text pattern matching
    if (searchPatterns.catalog && !searchPatterns.catalog.test(row.KW_CATALOGUE ?? '')) return false;
    if (searchPatterns.shortlabel1 && !searchPatterns.shortlabel1.test(row.SHORTLABEL1 ?? '')) return false;
    if (searchPatterns.model && !searchPatterns.model.test(row.MODEL ?? '')) return false;
    if (searchPatterns.buildingBlock && !searchPatterns.buildingBlock.test(row.MASTER_BLOCK_ID ?? '')) return false;
    if (searchPatterns.buildingBlockName && !searchPatterns.buildingBlockName.test(row.MASTER_BLOCK ?? '')) return false;

    // Multi-value SKU and ASIN matching
    if (skuArray.length > 0 && !skuArray.some(sku => 
      (row.ARTICLE_NUMBER ?? '').toLowerCase().includes(sku.toLowerCase())
    )) return false;

    if (asinArray.length > 0 && !asinArray.some(asin => 
      (row.ASIN ?? '').toLowerCase().includes(asin.toLowerCase())
    )) return false;

    // Exact matches (case-insensitive)
    if (lowercaseFilters.categoryManager && row.CATEGORY_MANAGER?.toLowerCase() !== lowercaseFilters.categoryManager) return false;
    if (lowercaseFilters.category && row.CATEGORY?.toLowerCase() !== lowercaseFilters.category) return false;

    // Boolean/numeric filters
    if (filters.overstock && (row.OVERSTOCK === 0 || row.OVERSTOCK === null)) return false;
    if (filters.newStarting && !row.NEW_STARTING) return false;
    if (filters.rangeStockCritical && row.STOCK >= 10) return false;
    if (filters.isDoNotOrder && !row.IS_DO_NOT_ORDER) return false;
    if (filters.oosFlag && !row.OOS_FLAG) return false;
    if (filters.purchaseOrderTimeframeExceeded && row.PURCHASE_ORDER_TIMEFRAME_EXCEEDED) return false;
    

    return true;
  });
};

const EktContext = createContext();

export const EktProvider = ({ children }) => {
  const [selectedMainView, setSelectedMainView] = useState("REORDER");
  const [mainViewData, setMainViewData] = useState([]);
  const [selectedRowMain, setSelectedRowMain] = useState(null);
  const [stockViewData, setStockViewData] = useState([]);
  const [stockOWHViewData, setStockOWHViewData] = useState([]);
  const [supplierOrdersData, setSupplierOrdersData] = useState([]);
  const [viewStates, setViewStates] = useState({
    mainView: { loading: false, error: null },
    sideOneView: { loading: false, error: null },
    sideTwoView: { loading: false, error: null },
  });
  const [pinSidebar, setPinSidebar] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [categoryManagers, setCategoryManagers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const [defaultCategoryManagers, setDefaultCategoryManagers] = useState([]);
  const [models, setModels] = useState([]);
  const [defaultModels, setDefaultModels] = useState([]);
  const [cachedData, setcachedData] = useState({
    reorder_data: [],
    fba_data: [],
    whe_data: [],
    stock_data: [],
    stock_data_owh: [],
    supplier_orders_data: [],
  });
  const defaultFilters = {
    catalog: "",
    categoryManager: "",
    category: "",
    asin: "",
    sku: "",
    shortlabel1: "",
    model: "",
    buildingBlock: "",
    buildingBlockName: "",
    allArticles: true,
    noArticleBundleList: false,
    rangeStockCritical: false,
    purchaseOrderTimeframeExceeded: false,
    overstock: false,
    newStarting: false,
    isDoNotOrder: false,
  }
  const [filters, setFilters] = useState(defaultFilters);

  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  let countDataLoaded = 0;
  const loadLists = (sourceData=mainViewData) => {
    // Use the passed sourceData parameter to ensure we're using the latest data
    const uniqueCategories = [
      ...new Set(
        sourceData
          .map((item) => item.CATEGORY)
          .filter((cm) => cm)
          .sort()
      ),
    ];
    setCategories(uniqueCategories);
  
    const uniqueCategoryManagers = [
      ...new Set(
        sourceData
          .map((item) => item.CATEGORY_MANAGER)
          .filter((cm) => cm)
          .sort()
      ),
    ];
    uniqueCategoryManagers.sort();
    setCategoryManagers(uniqueCategoryManagers);

    const uniqueModels = [
      ...new Set(
        sourceData
          .map((item) => item.MODEL)
          .filter((cm) => cm)
          .sort()
      ),
    ];
    setModels(uniqueModels);
    return {
      uniqueCategories, 
      uniqueCategoryManagers,
      uniqueModels
    };
  };

  useEffect(() => {
    console.log("Inside useEffect, Initial Data Loaded:", isInitialDataLoaded);
    if (!isInitialDataLoaded) {
    const fetchInitialData = async () => {
      setViewStates((prev) => ({
        ...prev,
        mainView: { loading: true, error: null },
        sideOneView: { loading: true, error: null },
      }));
      try {
        const [articles, stockData, stockDataOWH, supplierOrderData] =
          await Promise.all([
            fetchArticles(),
            fetchFBAStockData(),
            fetchOWHStockData(),
            fetchSupplierOrders(),
          ]);
          
          setcachedData ({
            reorder_data: articles.reorder ?? [],
            fba_data: articles.fba ?? [],
            wheu_data: articles.wheu ?? [],
            // pmt: pmt,
            stock_data: stockData,
            stock_data_owh: stockDataOWH,
            supplier_orders_data: supplierOrderData,
          });
          const {uniqueCategories, uniqueCategoryManagers, uniqueModels} = loadLists(articles.reorder);
          setDefaultCategories(uniqueCategories);
          setDefaultCategoryManagers(uniqueCategoryManagers);
          setDefaultModels(uniqueModels);
          setMainViewData(articles.reorder);
        

        console.log("caching data");
        console.log("articles.reorder[0]", articles.reorder[0]);
        countDataLoaded++;

        setStockViewData(stockData);
        setStockOWHViewData(stockDataOWH);
        setSupplierOrdersData(supplierOrderData);
        setIsInitialDataLoaded(true);

      } catch (err) {
        setViewStates((prev) => ({
          ...prev,
          mainView: { loading: false, error: err.message },
          sideOneView: { loading: false, error: err.message },
        }));
      } finally {
        setViewStates((prev) => ({
          ...prev,
          mainView: { loading: false, error: null },
          sideOneView: { loading: false, error: null },
        }));
      }
    };

      fetchInitialData();
    }
  }, []);

  const fetchMainViewData = async () => {
    setViewStates((prev) => ({
      ...prev,
      mainView: { loading: true, error: null },
    }));
    try {
        const main_data = await fetchArticles();
        setMainViewData(main_data.reorder);
        setcachedData(prevData => ({
          ...prevData,
          reorder_data: main_data.reorder,
          fba_data: main_data.fba,
          wheu_data: main_data.wheu,
        }));
    } catch (err) {
      handleResetDataFilters();
      setViewStates((prev) => ({
        ...prev,
        mainView: { loading: false, error: err.message },
      })
    );
    } finally {
      loadLists();
      setViewStates((prev) => ({
        ...prev,
        mainView: { loading: false, error: null },
      }));
    }
  };

  const handleRefreshData = async () => {
    setViewStates((prev) => ({
      ...prev,
      mainView: { loading: true, error: null },
    }));
    try {
      const message = await refreshArticles();
      console.log(message);
      await fetchMainViewData();
    } catch (err) {
      setViewStates((prev) => ({
        ...prev,
        mainView: { loading: false, error: err.message },
      }));
    } finally {
      setViewStates((prev) => ({
        ...prev,
        mainView: { loading: false, error: null },
      }));
    }
  };

  const handleResetDataFilters = () => {
    if (cachedData.reorder_data.length > 0) {
      console.log("Data reset cached");
      setMainViewData(cachedData.reorder_data);
      setStockViewData(cachedData.stock_data);
      setStockOWHViewData(cachedData.stock_data_owh);
      setSupplierOrdersData(cachedData.supplier_orders_data);
    } else {
      console.log("Data reset fetched from API");
      fetchMainViewData();
    }
    setFilters(defaultFilters);
    setCategories(defaultCategories);
    setCategoryManagers(defaultCategoryManagers);
    setModels(defaultModels);
    setViewStates((prev) => ({
      ...prev,
      mainView: { loading: false, error: null },
    }));
  };

  const fetchSideOneViewData = useCallback(
    async (asins, skus) => {
      if (isInitialDataLoaded ) {
        setViewStates((prev) => ({
          ...prev,
          sideOneView: { loading: true, error: null },
        }));

        const delayedFetch = () =>
          new Promise((resolve) => {
            setTimeout(async () => {
              try {
                const currentAsins = asins || mainViewData.map((row) => row.ASIN).filter(Boolean);
                const currentSkus = skus || mainViewData.map((row) => row.ARTICLE_NUMBER).filter(Boolean);

                const [stock_data, stock_data_owh, supplier_orders_data] =
                  await Promise.all([
                    fetchFBAStockData(currentAsins, currentSkus),
                    fetchOWHStockData(currentAsins, currentSkus),
                    fetchSupplierOrders(currentAsins, currentSkus),
                  ]);

                setStockViewData(stock_data);
                setStockOWHViewData(stock_data_owh);
                setSupplierOrdersData(supplier_orders_data);
                resolve();
              } catch (err) {
                setViewStates((prev) => ({
                  ...prev,
                  sideOneView: { loading: false, error: err.message },
                }));
                resolve();
              }
            }, 1000);
          });

        await delayedFetch();
        setViewStates((prev) => ({
          ...prev,
          sideOneView: { loading: false, error: null },
        }));
      }
    },
    [isInitialDataLoaded]
  );

  
  const handleFilterChange = (filterName, value) => {
    const newFilters = { ...filters, [filterName]: value };

    if (filterName === 'allArticles') {
      newFilters.noArticleBundleList = false;
      newFilters.rangeStockCritical = false;
      newFilters.purchaseOrderTimeframeExceeded = false;
      newFilters.overstock = false;
      newFilters.newStarting = false;

    }
    if (!filters.noArticleBundleList && 
        !filters.rangeStockCritical && 
        !filters.purchaseOrderTimeframeExceeded && 
        !filters.overstock && 
        !filters.newStarting){
      newFilters.allArticles = false;
    };
    if (JSON.stringify(newFilters) === JSON.stringify(defaultFilters)) {
      handleResetDataFilters();
    }
    else {
      setFilters(newFilters);
      applyFilters(newFilters);
    }
  };

  const applyFilters = async (newFilters) => {
    console.log("Applying newFilters", newFilters);
    countDataLoaded++;
    setViewStates((prev) => ({
      ...prev,
      mainView: { loading: true, error: null },
    }));
    try {
      const sourceData = selectedMainView === 'REORDER' 
      ? cachedData.reorder_data 
      : selectedMainView === 'WHEU'
      ? cachedData.wheu_data
      : selectedMainView === 'FBA'
      ? cachedData.fba_data
      : cachedData.fba_data;
    
      const filteredData = filterData(sourceData, newFilters);
      loadLists(filteredData);
      setMainViewData(filteredData);
      
      // Only fetch side view data if needed
      if (filteredData.length > 0) {
        const asins = filteredData.map(row => row.ASIN).filter(Boolean);
        const skus = filteredData.map(row => row.ARTICLE_NUMBER).filter(Boolean);
        await fetchSideOneViewData(asins, skus);

      }
      // const filteredArticles = await fetchFilteredArticles(filters, selectedMainView);
      // setMainViewData(filteredArticles);

      // const asins = filteredArticles.map((row) => row.ASIN).filter(Boolean);
      // const skus = filteredArticles.map((row) => row.ARTICLE_NUMBER).filter(Boolean);

      // await fetchSideOneViewData(asins, skus);

    } catch (err) {
      setViewStates((prev) => ({
        ...prev,
        mainView: { loading: false, error: err.message },
      }));
    } finally {
      setViewStates((prev) => ({
        ...prev,
        mainView: { loading: false, error: null },
      }));
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
    if (!isSidebarOpen) {
      setPinSidebar(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      selectedMainView,
      setSelectedMainView,
      mainViewData,
      setMainViewData,
      fetchMainViewData,
      stockViewData,
      setStockViewData,
      stockOWHViewData,
      setStockOWHViewData,
      supplierOrdersData,
      setSupplierOrdersData,
      fetchSideOneViewData,
      viewStates,
      handleRefreshData,
      isSidebarOpen,
      toggleSidebar,
      filters,
      handleFilterChange,
      applyFilters,
      selectedRowMain,
      setSelectedRowMain,
      categoryManagers,
      categories,
      models,
      cachedData,
      handleResetDataFilters,
      pinSidebar,
      setPinSidebar,
    }),
    [
      selectedMainView,
      mainViewData,
      stockViewData,
      stockOWHViewData,
      supplierOrdersData,
      viewStates,
      isSidebarOpen,
      filters,
      selectedRowMain,
      categoryManagers,
      fetchSideOneViewData,
      handleFilterChange,
      applyFilters,
      toggleSidebar,
    ]
    );

  return (
  <EktContext.Provider value={contextValue}>{children}</EktContext.Provider>
  );
};

export const useEktContext = () => useContext(EktContext);
