import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import BaseComponent from '../base-component';
import styles from './container.module.css';


const TabsContainer = ({
  tabs,
  defaultActiveTab = 0,
  sx,
  className,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTab];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [activeTab]);

  const tabsStyle = {
    display: 'flex',
    flexDirection: 'column',
    ...sx,
  };

  return (
    <BaseComponent
    color='transparent'
    elevation='sm'
    margin='none'
    className={`tabs ${className || ''}`}
    {...props}
    sx={tabsStyle}
    >
      <div className="tab-list flex relative overflow-x-auto scrollbar-hide" role="tablist">
        {tabs.map((tab, index) => (
          <button
            key={index}
            ref={(el) => (tabsRef.current[index] = el)}
            role="tab"
            aria-selected={activeTab === index}
            aria-controls={`tabpanel-${index}`}
            id={`tab-${index}`}
            tabIndex={activeTab === index ? 0 : -1}
            onClick={() => setActiveTab(index)}
            className={`${styles.tabButton} 
            ${activeTab === index ? 'text-primary border-b-2 border-primary' : 'text-gray-600'} 
            border-2 border-inherit shadow-md`}
          >
            {tab.title}
          </button>
        ))}
        <span 
          className={styles.tabUnderline}
          style={{
            left: tabUnderlineLeft,
            width: tabUnderlineWidth,
          }}
        />
      </div>
      <div className="tab-content">
        {tabs.map((tab, index) => (
          <div
            key={index}
            role="tabpanel"
            aria-labelledby={`tab-${index}`}
            id={`tabpanel-${index}`}
            hidden={activeTab !== index}
            className="tab-panel animate-fadeIn"
          >
            {tab.content}
          </div>
        ))}
      </div>
    </BaseComponent>
  );
};

TabsContainer.propTypes = {
  ...BaseComponent.propTypes,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  defaultActiveTab: PropTypes.number,
};

export default TabsContainer;