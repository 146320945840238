import React, { useState } from 'react';
import { useEktContext } from '../../../apps/ekt/context/EktContext';
export const Pin = ({ width = "200px", height = "200px", fill = "#000000", onClick, className }) => {
  const [isRotated, setIsRotated] = useState(false);
  const [currentFill, setCurrentFill] = useState(fill);
  const { pinSidebar, setPinSidebar } = useEktContext();

  const handleClick = (event) => {
    setPinSidebar(!pinSidebar);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <svg
      fill={pinSidebar ? "#FFFFFF" : "#000000"}
      height={height}
      width={width}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 490.125 490.125"
      xmlSpace="preserve"
      onClick={handleClick}
      transform={pinSidebar ? "rotate(-45)matrix(1, 0, 0, 1, 0, 0)" : undefined}
      className={className}
    >
      <g>
        <g>
          <g>
            <path d="M300.625,5.025c-6.7-6.7-17.6-6.7-24.3,0l-72.6,72.6c-6.7,6.7-6.7,17.6,0,24.3l16.3,16.3l-40.3,40.3l-63.5-7 c-3-0.3-6-0.5-8.9-0.5c-21.7,0-42.2,8.5-57.5,23.8l-20.8,20.8c-6.7,6.7-6.7,17.6,0,24.3l108.5,108.5l-132.4,132.4 c-6.7,6.7-6.7,17.6,0,24.3c3.3,3.3,7.7,5,12.1,5s8.8-1.7,12.1-5l132.5-132.5l108.5,108.5c3.3,3.3,7.7,5,12.1,5s8.8-1.7,12.1-5 l20.8-20.8c17.6-17.6,26.1-41.8,23.3-66.4l-7-63.5l40.3-40.3l16.2,16.2c6.7,6.7,17.6,6.7,24.3,0l72.6-72.6c3.2-3.2,5-7.6,5-12.1 s-1.8-8.9-5-12.1L300.625,5.025z M400.425,250.025l-16.2-16.3c-6.4-6.4-17.8-6.4-24.3,0l-58.2,58.3c-3.7,3.7-5.5,8.8-4.9,14 l7.9,71.6c1.6,14.3-3.3,28.3-13.5,38.4l-8.7,8.7l-217.1-217.1l8.7-8.6c10.1-10.1,24.2-15,38.4-13.5l71.7,7.9 c5.2,0.6,10.3-1.2,14-4.9l58.2-58.2c6.7-6.7,6.7-17.6,0-24.3l-16.3-16.3l48.3-48.3l160.3,160.3L400.425,250.025z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

