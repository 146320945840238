import axios, { AxiosHeaders, AxiosInstance, InternalAxiosRequestConfig } from 'axios';

// Function to retrieve the token. Modify this function to get the token from your storage.
const getToken = (): string | null => {
  return localStorage.getItem('access_token');
};

// Define the API URL based on the environment
const getApiBaseUrl = (): string => {
  if (process.env.REACT_APP_NODE_ENV === "test") {
    console.log('Running on local environment');
    return 'http://localhost:8000';  
  }
  
  // For production/development environments, use the current origin
  return window.location.origin;
};

const apiClient: AxiosInstance = axios.create({
  baseURL: `${getApiBaseUrl()}/api/v1/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add this console.log to debug the base URL
console.log('API Base URL:', getApiBaseUrl());


// Interceptor to add the Authorization header to each request
apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    // Ensure headers are properly initialized
    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const get = (url: string) => apiClient.get(url);
export const post = (url: string, data: any) => apiClient.post(url, data);
