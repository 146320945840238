import React, { useEffect } from "react";
import { useApplicationContext } from "../../context/AppContext";
import MessagePage from "../components/MessagePage";
import {
  checkAuthTokenValidity,
  exchangeCodeForToken,
} from "../handlers/authHandler";

export default function GetToken() {
  // const initialized = useRef(false);
  const { setIsAuthenticated } = useApplicationContext();
  const redirect = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    const handleTokenExchange = async () => {
      if (code) {
        console.log("exchanging code for token");
          await exchangeCodeForToken(code);
          await checkAuthTokenValidity();
          setIsAuthenticated(true);
          redirect();
      } else {
        console.log("no code found");
        redirect();
      }
    };
    handleTokenExchange();
  }, [setIsAuthenticated]);

  return (
    <>
      <MessagePage
        mainMessage="Redirecting"
        subMessage="You will be redirected shortly"
        linkText="Return to Home"
        linkHref="/"
      />
    </>
  );
}
