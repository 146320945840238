import PropTypes from 'prop-types';
import React from 'react';
import baseTheme from '../../../styles/theme';
import BaseComponent from '../base-component';

const Container = ({
  children,
  layout = 'column',
  sx,
  className,
  ...props
}) => {

  const containerStyle = {
    ...baseTheme.components.container,
    ...sx,
  };

  const layoutClasses = {
    default: 'flex flex-col',
    column: 'flex flex-col gap-4',
    row: 'flex flex-row gap-4 overflow-x-auto',
    grid: 'grid grid-cols-1 md:grid-cols-3 auto-rows-min gap-6',
    list: 'flex flex-col gap-4 overflow-y-auto',
  };

  const containerClassName = `w-full h-full ${layoutClasses[layout]} ${className || ''}`;

  return (
    <BaseComponent
      {...props}
      className={containerClassName}
      sx={containerStyle}
    >
      {children}
    </BaseComponent>
  );
};

Container.propTypes = {
  ...BaseComponent.propTypes,
  layout: PropTypes.oneOf(['default', 'column', 'row', 'grid']),
};

export default Container;