
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef, useState } from 'react';
import { useEktContext } from '../../context/EktContext';

const MainDataView = ({ viewType }) => {
  const { 
      mainViewData, 
      error, 
      setSelectedRowMain, 
      selectedRowMain,
    } = useEktContext();
    
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
      if (selectedRows.length > 0) {
        setSelectedRowMain(selectedRows);
      } else {
        setSelectedRowMain(null);
      }
  }, [selectedRowMain]);

  const getRowClass = params => {
    if (params.node.isSelected()) {
      return 'selected-row';
    }
  };

  if (error) return <div>Error: {error}</div>;

  const IntegerFormatter = (params) => {
    if (params.value === null) {
      return '';
    }
    return Math.ceil(params.value).toLocaleString();
  };
  const FloatDoubleDigitFormatter = (params) => {
    if (params.value === null || params.value === undefined) {
      return '';
    }
    const value = Number(params.value);
    if (isNaN(value)) {
      return '';
    }
    return value.toFixed(2).toLocaleString();
  };

  const DateFormatter = (params) => {
    if (params.value === null || typeof params.value !== 'string') {
      return '';
    }
    const date = new Date(params.value);
    if (isNaN(date.getTime())) {
      return params.value; // Return original value if it's not a valid date
    }
    return date.toISOString().slice(0, 10).replace(/-/g, '-');
  };

  const SmallCellWidth = 100;
  const MediumCellWidth = 150;
  const LargeCellWidth = 300;

  const articleColumns = [
    // { headerName: 'Surrogate Key', field: 'SURROGATE_KEY', sortable: true },
    { headerName: 'Article Nr', field: 'ARTICLE_NUMBER', sortable: true, width: MediumCellWidth },
    { headerName: 'Product Description', field: 'SHORTLABEL1', sortable: true, width: LargeCellWidth },
    { headerName: 'Stock', field: 'STOCK', sortable: true, valueFormatter: IntegerFormatter, width: SmallCellWidth },
    { headerName: 'Buy Price', field: 'BUY_PRICE', sortable: true, valueFormatter: FloatDoubleDigitFormatter, width: SmallCellWidth },
    { headerName: 'Stock Value', field: 'STOCK_VALUE', sortable: true, valueFormatter: FloatDoubleDigitFormatter, width: SmallCellWidth },
    { headerName: 'Overstock', field: 'OVERSTOCK', sortable: true, valueFormatter: IntegerFormatter, width: SmallCellWidth },
    { headerName: 'Preordered', field: 'PREORDERED', sortable: true, valueFormatter: FloatDoubleDigitFormatter, width: SmallCellWidth },
    { headerName: 'Current Monthly Sales', field: 'CURRENT_MONTHLY_SALES', sortable: true, valueFormatter: FloatDoubleDigitFormatter, width: MediumCellWidth },
    { headerName: 'Order Volume', field: 'ORDER_VOLUME', sortable: true, valueFormatter: FloatDoubleDigitFormatter, width: 150 },
    { headerName: 'Suggested Order Volume', field: 'ORDER_VOLUME_SUGGESTED', sortable: true, valueFormatter: IntegerFormatter },
    { headerName: 'Range Ex', field: 'RANGE_EX', sortable: true, valueFormatter: IntegerFormatter, width: SmallCellWidth },
    { headerName: 'Range In', field: 'RANGE_IN', sortable: true, valueFormatter: IntegerFormatter, width: SmallCellWidth },
    { headerName: 'Sales Velocity', field: 'SALES_VELOCITY', sortable: true, valueFormatter: FloatDoubleDigitFormatter },
    { headerName: '30D Sales Prediction', field: 'SALES_PREDICTION_30D', sortable: true, valueFormatter: FloatDoubleDigitFormatter },
    { headerName: 'Current Monthly Turnover', field: 'CURRENT_MONTHLY_TURNOVER', sortable: true, valueFormatter: FloatDoubleDigitFormatter },
    { headerName: 'OOS Flag', field: 'OOS_FLAG', sortable: true },
    { headerName: 'OOS Monthly Sales', field: 'OOS_MONTHLY_SALES', sortable: true, valueFormatter: FloatDoubleDigitFormatter },
    { headerName: 'Currency', field: 'CURRENCY', sortable: true },
    { headerName: 'Region', field: 'REGION', sortable: true },
    { headerName: 'Listing Date', field: 'LISTING_DATE', sortable: true, valueFormatter: DateFormatter },
    { headerName: 'Created at', field: 'CREATED_DATE', sortable: true, valueFormatter: DateFormatter },
    { headerName: 'Model', field: 'MODEL', sortable: true },
    { headerName: 'Master Block', field: 'MASTER_BLOCK', sortable: true },
    { headerName: 'Master Block ID', field: 'MASTER_BLOCK_ID', sortable: true },
    { headerName: 'Category', field: 'CATEGORY', sortable: true },
    { headerName: 'Category Manager', field: 'CATEGORY_MANAGER', sortable: true },
    { headerName: 'New Starting', field: 'NEW_STARTING', sortable: true },
    { headerName: 'KW Group', field: 'KW_WAREGROUP', sortable: true },
    { headerName: 'KW Cat', field: 'KW_CATALOGUE', sortable: true },
  ];

  return (
    <div>
      <div className="ag-theme-balham" 
        style={{ 
            height: 400, 
            width: '100%',
            maxWidth: '100%', 
            overflow: 'auto' 
          }}
            >
        <AgGridReact
          ref={gridRef}
          columnDefs={articleColumns}
          rowData={mainViewData}
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSizeSelector={false}
          paginationPageSize={1000}
          onSelectionChanged={onSelectionChanged}
          rowSelection="multiple"
          domLayout='normal'
          defaultColDef={{
            sortable: true,
            resizable: true,
            autoHeight: true,
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          }}
          autoHeaderHeight={true}
          sortingOrder={['asc', 'desc']}
          getRowClass={getRowClass}
          enableCellTextSelection={true}
        />
      </div>
    </div>
  );
};

export const StandardView = () => <MainDataView />;
export const PMTView = () => <MainDataView viewType="PMT" />;

export default MainDataView;