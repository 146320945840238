import React from 'react';

interface CheckboxProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function Checkbox({ id, label, checked, onChange }: CheckboxProps) {
  return (
    <div className="flex items-center space-x-2">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="
          w-4 h-4 rounded 
          border-teal-400/30 
          bg-teal-500/20 
          text-teal-600 
          focus:ring-teal-500
          shadow-sm
          m-1.5
        "
      />
      <label htmlFor={id} className="text-sm font-medium cursor-pointer">
        {label}
      </label>
    </div>
  );
}