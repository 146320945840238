import PropTypes from 'prop-types';
import React from 'react';

// Utility components that leverage Tailwind to provide spacing, division, and layout improvements

export const Spacer = ({ size = 2, axis = 'vertical', className = '' }) => {
  const baseClass = axis === 'vertical' ? 'mt' : 'ml';
  return <div className={`${baseClass}-${size} ${className}`} />;
};

Spacer.propTypes = {
  size: PropTypes.number,
  axis: PropTypes.oneOf(['vertical', 'horizontal']),
  className: PropTypes.string,
};

export const Divider = ({ color = 'gray-300', size = 2, className = '' }) => {
  return (
    <hr className={`border-t border-${color} border-${size} ${className}`} />
  );
};

Divider.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

// Flexible container component for creating responsive layouts
export const FlexContainer = ({ children, direction = 'row', wrap = 'nowrap', justify = 'start', items = 'stretch', className = '' }) => {
  return (
    <div className={`flex flex-${direction} flex-${wrap} justify-${justify} items-${items} ${className}`}>
      {children}
    </div>
  );
};

FlexContainer.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['row', 'col', 'row-reverse', 'col-reverse']),
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  justify: PropTypes.oneOf(['start', 'end', 'center', 'between', 'around', 'evenly']),
  items: PropTypes.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
  className: PropTypes.string,
};

// Grid container component for creating responsive grid layouts
export const GridContainer = ({ children, cols = 1, gap = 4, className = '' }) => {
  return (
    <div className={`grid grid-cols-${cols} gap-${gap} ${className}`}>
      {children}
    </div>
  );
};

GridContainer.propTypes = {
  children: PropTypes.node,
  cols: PropTypes.number,
  gap: PropTypes.number,
  className: PropTypes.string,
};

// Responsive padding component
export const Padding = ({ children, p = 4, px, py, pt, pr, pb, pl, className = '' }) => {
  const paddingClasses = [
    px !== undefined ? `px-${px}` : '',
    py !== undefined ? `py-${py}` : '',
    pt !== undefined ? `pt-${pt}` : '',
    pr !== undefined ? `pr-${pr}` : '',
    pb !== undefined ? `pb-${pb}` : '',
    pl !== undefined ? `pl-${pl}` : '',
    px === undefined && py === undefined && pt === undefined && pr === undefined && pb === undefined && pl === undefined ? `p-${p}` : '',
  ].filter(Boolean).join(' ');

  return (
    <div className={`${paddingClasses} ${className}`}>
      {children}
    </div>
  );
};

Padding.propTypes = {
  children: PropTypes.node,
  p: PropTypes.number,
  px: PropTypes.number,
  py: PropTypes.number,
  pt: PropTypes.number,
  pr: PropTypes.number,
  pb: PropTypes.number,
  pl: PropTypes.number,
  className: PropTypes.string,
};

