import PropTypes from 'prop-types';
import React from 'react';
import BaseComponent from '../base-component';

const BaseNavBar = ({ title, rightContent, ...props }) => {  
  return (
    <BaseComponent
      as="nav"
      color="none"
      elevation="none"
      padding="sm"
      className="sticky flex-none flex-no-wrap flex  justify-between items-center top-0 h-16 bg-white border-b border-gray-200 px-4"
      {...props}

    >
      <div className="flex items-center">
        {/* Sellerx Logo */}
      <img 
          src="/SellerX_Logo_White.svg" 
          alt="SellerX Logo" 
          style={{ margin: "1rem", height: "2rem" }}
            />

        <h1 className="ml-4 text-lg"
        style={{
          color: 'var(--color-gray-800)',
          fontWeight: 'bold',
          fontSize: '1.5rem',
        }}
        >{title}</h1>
      </div>
      
      <div className="flex items-center space-x-2">
        {rightContent}
      </div>
    </BaseComponent>
  );
};


BaseNavBar.propTypes = {
  title: PropTypes.string.isRequired,
  rightContent: PropTypes.node,
};

export default BaseNavBar;