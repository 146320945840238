import React, { useEffect, useState } from 'react';
import { getLoginUrl } from '../handlers/authHandler';
import MessagePage from '../components/MessagePage';

const Unauthorized: React.FC = () => {
  const [loginUrl, setLoginUrl] = useState('');

  useEffect(() => {
      const fetchLoginUrl = async () => {
	  try {
	      const url = await getLoginUrl();
	      setLoginUrl(url);
	  } catch (error) {
	      // Handle error appropriately
	      console.error("Error fetching login URL:", error);
	  }
      };

      fetchLoginUrl();
  }, []);

  return (
    <MessagePage 
      mainMessage="Unauthorized" 
      subMessage="You are not allowed to access this page. Please login with your credentials."
      shortMessage="401 error"
      linkText="Login with Microsoft" 
      linkHref={loginUrl} 
    />
  )
}

export default Unauthorized
