import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from 'styled-components';
import BaseComponent from '../base-component';

const BaseInput = ({ label, labelPosition = 'row', size = 'sm', className, multiple, ...props }) => {
  const theme = useTheme();
  const inputStyle = {
    ...theme.components.input,
    ...(size === 'sm' ? { padding: '0.25rem 0.5rem', fontSize: '0.75rem' } : {}),
    ...(size === 'md' ? { padding: '0.25rem 0.5rem', fontSize: '0.975rem' } : {}),
    ...(size === 'lg' ? { padding: '0.75rem 1rem', fontSize: '1.125rem' } : {}),
    ...(multiple ? { height: 'auto', minHeight: '100px' } : {}),
  };

  return (
    <BaseComponent 
      as="div" 
      elevation='none'
      padding='sm'
      margin='none'
      className={`flex ${labelPosition === 'row' ? 'flex-row items-center' : 'flex-col'} mb-1 `}>
      {label && (
        <label htmlFor={props.id} className={`mb-0.5 text-sm font-medium text-gray-700 ${labelPosition === 'row' ? 'mr-2' : ''}`}>
          {label}
        </label>
      )}
      <BaseComponent
        as={props.as || "input"}
        className={`
          placeholder:text-white 
          px-3 py-1 
          border border-gray-300 
          rounded-md 
          shadow-sm 
          transition-all duration-200
          hover:border-gray-400
          focus:outline
          focus:outline-gray-600
          focus:outline-2
          ${multiple ? 'overflow-y-auto' : ''}
          ${className || ''}
        `}
        sx={inputStyle}
        multiple={multiple}
        {...props}
      />
    </BaseComponent>
  );
};

BaseInput.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['column', 'row']),
  multiple: PropTypes.bool,
  ...BaseComponent.propTypes,
};

export default BaseInput;