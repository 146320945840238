import React, { createContext, useState, useContext } from 'react';
import { logout } from '../common/handlers/authHandler';

const ApplicationContext = createContext();

export const ApplicationProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const handleLogout = () => {
        setIsAuthenticated(false);
        logout();
    };

    return (
        <ApplicationContext.Provider value={{ 
            isAuthenticated,
            setIsAuthenticated,
            handleLogout,
        }}>
            {children}
        </ApplicationContext.Provider>
    );
};

export const useApplicationContext = () => useContext(ApplicationContext);