// src/apps/ekt/components/EktTopBar.jsx
import React from "react";
import BaseNavBar from "../../../../common/components/bars/base-navbar";
import BaseButton from "../../../../common/components/buttons/base-button";
import { logout } from "../../../../common/handlers/authHandler";
import { useApplicationContext } from "../../../../context/AppContext";
import { useEktContext } from "../../context/EktContext";

const EktTopBar = () => {
  const { isAuthenticated } = useApplicationContext();
  const { isSidebarOpen, toggleSidebar } = useEktContext();

  return (
    <BaseNavBar
    margin="none"
    sx={{
      backgroundColor: 'var(--color-primary)',
      position: 'sticky',
      zIndex: 1000,
      top: 0,
    }}
      title="EKT"
      rightContent={
        <>
          <BaseButton 
            onClick={toggleSidebar} 
            color="primary"
            >
          <p className="font-bold"
          >
            {isSidebarOpen ? "✕" : "☰"}
          </p>
          </BaseButton>

          {isAuthenticated ? (
            <BaseButton color="secondary"
            onClick={logout}
            >
              Logout
            </BaseButton>
          ) : (
            <BaseButton
              color="primary"
              onClick={() => {/* handle login */}}
            >
              Login
            </BaseButton>
          )}
        </>
      }
    />
  );
};

export default EktTopBar;
