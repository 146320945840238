import PropTypes from "prop-types";
import React, { useState } from "react";
import baseTheme from "../../../styles/theme";
import BaseComponent from "../base-component";

const BaseButton = ({
  onClick,
  size = "small",
  color = "primary",
  children,
  sx,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    ...baseTheme.components.button,
    ...baseTheme.components.button[size],
    ...baseTheme.components.button[color],
    ...(isHovered
      ? {
          backgroundColor:
            baseTheme.components.button[color].hoverBackground,
        }
      : {}),
    ...sx,
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <BaseComponent
      as="button"
      color={color}
      sx={buttonStyle}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {children}
    </BaseComponent>
  );
};

BaseButton.propTypes = {
  ...BaseComponent.propTypes,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["xsmall","small", "medium", "large"]),
};

export default BaseButton;
