import MessagePage from '../components/MessagePage';

export default function NotFound() {
  return (
    <MessagePage
      shortMessage="404"
      mainMessage="Page not found"
      subMessage="Sorry, we couldn’t find the page you’re looking for."
      linkText="Go back home"
      linkHref="/"
    />
  )
}

