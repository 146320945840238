import { useEffect, useRef } from 'react';
import { useApplicationContext } from '../../context/AppContext';
import MessagePage from '../components/MessagePage';

export default function Logout() {
  const initialized = useRef(false)
  const { handleLogout } = useApplicationContext();

  const redirect = () => {
    window.location.href = '/';
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      handleLogout();
    }
    // logout();
    redirect();
  }, []);

  return (
    <>
      <MessagePage
      mainMessage="Logging out"
      subMessage="You will be redirected shortly"
      linkText="Return to Home"
      linkHref="/"
      />
    </>
  )
}
