import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import baseTheme from "../../../styles/theme";
import BaseComponent from "../base-component";

const BaseSidebar = ({
  children,
  isOpen,
  onClose,
  position = "left",
  behavior = "slide",
  sx,
  className,
  ...props
}) => {
  const [width, setWidth] = useState("250px");
  const [maxHeight, setMaxHeight] = useState("100%");
  const sidebarRef = useRef(null);
  const minWidth = '20rem';

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = window.innerHeight;
      let sidebarWidth = "400px";
      let sidebarMaxHeight = "100%";

      if (position === "top" || position === "bottom") {
        sidebarWidth = "auto";
        sidebarMaxHeight = `${viewportHeight * 0.2}px`;
      }

      setWidth(sidebarWidth);
      setMaxHeight(sidebarMaxHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [position]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const { innerWidth, innerHeight } = window;

      if (
        (position === "right" && clientX >= innerWidth - 10) ||
        (position === "left" && clientX <= 10) ||
        (position === "bottom" && clientY >= innerHeight - 10)
      ) {
        if (!isOpen) {
          onClose(true);
        }
      }
    };

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && behavior !== "pinned") {
        onClose(false);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose, position]);

  const positionClasses = {
    left: "left-0 top-0 bottom-0",
    right: "right-0 top-0 bottom-0",
    top: "top-0 left-0 right-0",
    bottom: "bottom-0 left-0 right-0",
  };

  const behaviorClasses = {
    slide: "transition-transform duration-350 ease-in-out",
    pinned: "transition-[width] duration-300 ease-in-out",
  };

  const transformClasses = {
    left: isOpen ? "translate-x-2" : "-translate-x-[200rem]", 
    right: isOpen ? "translate-x-0" : "translate-x-full",
    top: isOpen ? "translate-y-0" : "-translate-y-full",
    bottom: isOpen ? "translate-y-0" : "translate-y-full",
  };

  return (
    <BaseComponent
      as="aside"
      ref={sidebarRef}
      className={`
        fixed z-[1000] overflow-y-auto
        ${positionClasses[position]}
        ${behaviorClasses[behavior]}
        ${transformClasses[position]}
        ${className}
        `}
      {...props}
      sx={{
        ...baseTheme.components.sidebar,
        width: width,
        minWidth: minWidth,
        maxHeight: maxHeight,
        margin: baseTheme.spacing(baseTheme.margin['md']),
        
        ...sx,
      }}
    >
      {children}
    </BaseComponent>
  );
};

BaseSidebar.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  behavior: PropTypes.oneOf(["slide", "pinned"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  sx: PropTypes.object,
  className: PropTypes.string,
};

export default BaseSidebar;