import * as crypto from 'crypto-js';
import { post } from '../../services/api';

export const getLoginUrl = async () => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = generateCodeChallenge(codeVerifier);

  localStorage.setItem('code_verifier', codeVerifier);

  try {
    const response = await post('/auth/get-auth-url', { code_challenge: codeChallenge });
    if (!response.data || !response.data.auth_url) {
      console.error('Invalid response from server:', response);
      return null;
    }
    return response.data.auth_url;
  } catch (error) {
    console.error("Failed to fetch login URL:", error);
    throw error;
  }
};

const generateCodeVerifier = () => {
  return crypto.lib.WordArray.random(32).toString(crypto.enc.Base64url).replace(/=/g, '');
};

const generateCodeChallenge = (codeVerifier) => {
  return crypto.SHA256(codeVerifier)
    .toString(crypto.enc.Base64url)
    .replace(/=/g, '');
};

export const exchangeCodeForToken = async (code) => {
  const codeVerifier = localStorage.getItem('code_verifier');
  try {
    const response = await post('/auth/get-token', { code: String(code), code_verifier: String(codeVerifier) })
    
    localStorage.setItem('access_token', response.data.access_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    localStorage.setItem('expires_in', Date.now()  + response.data.expires_in * 1000);
    localStorage.removeItem('code_verifier');
    console.log("Token exchanged successfully");
    return true;
  } catch (error) {
    console.error('Error exchanging code for token:', error);
    return false;
  }
};

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    console.error('No refresh token available');
    return false;
  }
  try {
    const response = await post('/auth/refresh', { refresh_token: refreshToken });

    if (response.data && response.data.access_token) {
      let name = response.data.user_info?.name || "";
      console.log('name:', name);

      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      localStorage.setItem('expires_in', Date.now() + response.data.expires_in * 1000);

      return true;
    } else {
      console.error('Invalid response from server:', response);
      return false;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    logout();
    return false;
  }
};

export const checkAuthTokenValidity = async () => {
  const expiresIn = localStorage.getItem('expires_in');
  if (!expiresIn || Date.now() > parseInt(expiresIn)) {
    return await refreshToken();
  }
  return true;
};

export const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('expires_in');
  localStorage.removeItem('code_verifier');
  localStorage.removeItem('user');
  window.location.href = '/';
};