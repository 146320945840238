import React from "react";
import Box from "../../common/components/box";
import BaseButton from "../../common/components/buttons/base-button";
import Container from "../../common/components/containers/container";
import { Spacer } from "../../common/components/utils";
import SkeletonTable from "../../common/components/views/SkeletonTable";
import EktFilterSidebar from "./components/bars/EktFilterSidebar";
import EktTopBar from "./components/bars/EktTopBar";
import { StandardView } from "./components/views/EktMainViews";
import { TabsSideViewOne } from "./components/views/EktSideViewsOne";
import { useEktContext } from "./context/EktContext";

const EktUI = () => {
  const {
    isSidebarOpen,
    toggleSidebar,
    selectedMainView, 
    setSelectedMainView, 
    viewStates,
    pinSidebar,
    setMainViewData,
    cachedData,
  } = useEktContext();
  const availableViews = ["reorder","fba","wheu"];

  const handleViewChange = (event) => {
    const selectedView = event.target.value.toLowerCase();
    setSelectedMainView(selectedView.toUpperCase());
    if (cachedData) {
      switch (selectedView) {
        case "reorder":
          setMainViewData(cachedData.reorder_data);
          break;
        case "fba":
          setMainViewData(cachedData.fba_data);
          break;
        case "wheu":
          setMainViewData(cachedData.wheu_data);
          break;
        default:
          setMainViewData(cachedData.reorder_data);
      }
    }
  };

  const renderMainView = () => {
    if (viewStates.mainView.loading) {
      return <SkeletonTable rows={12} />;
    }
    return <StandardView />;

  };

  return (
    <Box 
    className="flex flex-col h-screen"
    padding="none"
    color="background"
    sx = {{
      minHeight: '96vh',
      maxWidth: '99.4%',
    }}
    >
    <EktTopBar
      onLoginClick={() => {
        /* handle login */
      }}
      isLogged={false}
    />
      <div 
      className="flex-1 overflow-y-auto"
      >
        <EktFilterSidebar 
        />
        <div className={`${pinSidebar && isSidebarOpen ? 'ml-[410px]' :  ''}`}>
          {isSidebarOpen && !pinSidebar && (
            <div
              className="fixed inset-0 bg-black bg-opacity-20 z-10"
              onClick={toggleSidebar}
            />
          )}
          <Spacer/>
          <Container  margin='none' className='gap-1'>
            <Box className="flex items-center" margin='none'>
              <BaseButton
                as="select"
                id="view-selector"
                size="xsmall"
                rounding='sm'
                value={selectedMainView}
                onChange={handleViewChange}
              >
                {availableViews.map((view) => (
                  <option key={view} value={view.toUpperCase()}>
                    {view.toUpperCase() }
                  </option>
                ))}
              </BaseButton>
            </Box>

            {renderMainView()}
          </Container>
          <Spacer/>
          <Container 
          margin='none'
          >
            {viewStates.sideOneView.loading ? (
              <SkeletonTable 
              tabs={3}
              rows={5}
              />
            ) : (
              <TabsSideViewOne/>
            )}
          </Container>
          <Spacer/>
        </div>
      </div>
    </Box>
  );
};

export default EktUI;
